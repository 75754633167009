/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/react-in-jsx-scope */

export default function Custom404() {

    return (
            <div className="container mx-auto flex flex-col justify-center items-center mt-67 mb-44 font-helvetica-text">
                <div className="mb-6 font-bold text-3xl ">¡Lo sentimos!</div>
                <h1 className="mb-8 w-73 text-lg text-center">No podemos encontrar lo que buscas. Parece que la página que solicitas no existe. </h1>
                <a className="underline text-blue text-lg cursor-pointer" href="/">Ir a Menu Principal</a>
                <div>
                </div>
            </div>
    );
}
/*
export async function getServerSideProps() {

    try {

        const { data: setting } = await client.query({
            query: TOPMENU_AND_FOOTERMENU_QUERY,
        });

        const { data: menu } = await client.query({
            query: CAT_MUJER_HOMBRE_QUERY,
        });

        return {
            props: {
                setting: setting?.arenbitThemeOptions ? setting?.arenbitThemeOptions : [],
                seo: setting?.generalSettings ? setting?.generalSettings : [],
                categories: setting?.menuItems?.nodes ? setting?.menuItems?.nodes : [],
                footerMenu: setting?.footerMenu?.nodes ? setting?.footerMenu?.nodes : [],
                menu: menu ? menu : [],
            },
           // revalidate: 60
        };
    } catch (error) {
        console.error(error);
        throw new Error("Error problemas de conexion con el servidor");
    }

}*/